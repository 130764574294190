<template>
  <div class="main-content">
    <h3 class="marginBottom20">
      详情-{{ form.type === 1 ? "二手房" : form.type === 2 ? "办公租赁" : "/" }}
    </h3>
    <avue-form
      ref="form"
      v-model="form"
      v-loading="loading"
      :option="formOption"
    >
      <template slot="titleOne">
        <h3>带看信息</h3>
      </template>
      <template slot="username"
        ><div>{{ form.username }}</div></template
      >
      <template slot="phone"
        ><div>{{ form.phone }}</div></template
      >
      <template slot="subscribeTime"
        ><div>{{ form.subscribeTime }}</div></template
      >
      <template slot="requireDesc"
        ><div>{{ form.requireDesc }}</div></template
      >
      <template slot="state">
        <div>
          {{
            form.state === 1
              ? "未带看"
              : form.state === 2
              ? "带看中"
              : form.state === 3
              ? "已带看"
              : form.state === 4
              ? "已取消"
              : "/"
          }}
        </div>
      </template>
      <template slot="titleTwo">
        <h3>房源信息</h3>
      </template>
      <template slot="titleTwoSonOne">
        <h4>基础信息</h4>
      </template>
      <template slot="title"
        ><div>{{ form.title }}</div></template
      >
      <template slot="city"
        ><div>{{ form.city }}{{ form.county }}</div></template
      >
      <template slot="business"
        ><div>{{ form.business }}</div></template
      >
      <template slot="name"
        ><div>{{ form.name }}</div></template
      >
      <template slot="buildNum">
        <div>
          {{ form.buildNum }} {{ form.isUnit === 1 ? form.unitNumber : "" }}
          {{ form.isHouse === 1 ? form.houseNumber : "" }}
        </div>
      </template>
      <template slot="rentalMode"
        ><div>
          {{
            form.rentalMode === 1
              ? "整租"
              : form.rentalMode === 2
              ? "合租"
              : "/"
          }}
        </div></template
      >
      <template slot="bedroom"
        ><div>
          {{ form.bedroom + " 室 " }}{{ form.office + " 厅 "
          }}{{ form.type === 2 ? form.toilet + " 卫" : "" }}
        </div></template
      >
      <template slot="floorage"
        ><div>{{ form.floorage }} 平米</div></template
      >
      <template slot="rentalArea"
        ><div>{{ form.rentalArea }} 平米</div></template
      >
      <template slot="sellPrice"
        ><div>{{ form.sellPrice }} 万元</div></template
      >
      <template slot="expectPrice"
        ><div>{{ form.expectPrice }} 元/㎡/天</div></template
      >
      <template slot="buildYear">
        <div>{{ form.buildYear }} 年</div>
        <el-button type="text" style="color: #ebb563"
          >实际房龄以政府信息为准</el-button
        >
      </template>
      <template slot="labelList"
        ><div>{{ labelList || "/" }}</div></template
      >
      <template slot="pledge"
        ><div>押 {{ form.pledge }} 付 {{ form.pay }}</div></template
      >
      <template slot="titleTwoSonTwo">
        <h4>房源信息</h4>
      </template>
      <template slot="orientation"
        ><div>{{ orientationText }}</div></template
      >
      <template slot="floor"
        ><div>{{ floorText }}</div></template
      >
      <template slot="floorCurrent"
        ><div>{{ form.floorCurrent }}/{{ form.floorTotal }}</div></template
      >
      <template slot="decorate"
        ><div>
          {{
            form.decorate === 1 ? "精装" : form.decorate === 2 ? "简装" : "/"
          }}
        </div></template
      >
      <!-- <template slot="elevator"><div>{{ form.elevator === 1 ? '无' : form.elevator === 2 ? '有' : '/' }}</div></template> -->
      <template slot="elevator"
        ><div>{{ form.elevator || "/" }}</div></template
      >
      <template slot="buildType"
        ><div>
          {{
            form.buildType === 1
              ? "板楼"
              : form.buildType === 2
              ? "塔楼"
              : form.buildType === 3
              ? "板塔结合"
              : "/"
          }}
        </div></template
      >
      <template slot="purpose"
        ><div>
          {{
            form.purpose === 1
              ? "普通住宅"
              : form.purpose === 2
              ? "办公"
              : form.purpose === 3
              ? "商住"
              : "/"
          }}
        </div></template
      >
      <template slot="ownership"
        ><div>
          {{
            form.ownership === 1
              ? "个人单独产权"
              : form.ownership === 2
              ? "个人多人产权"
              : form.ownership === 3
              ? "公司产权"
              : "/"
          }}
        </div></template
      >
      <template slot="mortgage"
        ><div>
          {{
            form.mortgage === 1
              ? "无抵押"
              : form.mortgage === 2
              ? "只有银行抵押"
              : form.mortgage === 3
              ? "有银行及其他抵押"
              : "/"
          }}
        </div></template
      >
      <template slot="checkType"
        ><div>
          {{ form.checkType === 1 ? "随时入住" : `${form.checkMonth}个月后` }}
        </div></template
      >
      <template slot="facilities"
        ><div>{{ facilitiesText }}</div></template
      >
      <template slot="heatMode"
        ><div>
          {{
            form.heatMode === 1
              ? "无"
              : form.heatMode === 2
              ? "自供暖"
              : form.heatMode === 3
              ? "集中供暖"
              : "/"
          }}
        </div></template
      >
      <template slot="ageLimit"
        ><div>
          {{
            form.ageLimit === 1
              ? "不满2年"
              : form.ageLimit === 2
              ? "满2年"
              : form.ageLimit === 3
              ? "满5年"
              : "/"
          }}
        </div></template
      >
      <template slot="lastTime"
        ><div>{{ form.lastTime }}</div></template
      >
      <template slot="isHardcover"
        ><div>{{ form.isHardcover === 1 ? "是" : "否" }}</div></template
      >
      <template slot="hydropower"
        ><div>{{ form.hydropower }}</div></template
      >
      <template v-if="form.type === 2"  slot="businessType"
          ><div>
            {{
              form.businessType === 1
                ? "写字楼"
                : form.businessType === 2
                ? "创意园区"
                : form.businessType === 3
                ? "共享办公"
                : "/"
            }}
          </div></template
        >
        <template v-if="form.type === 2" slot="floorTotal"
          ><div>{{ form.floorTotal || "/" }}</div></template
        >
        <template v-if="form.type === 2" slot="floorHeight"
          ><div>{{ form.floorHeight || "/" }}</div></template
        >
        <template v-if="form.type === 2" slot="singleLayerArea"
          ><div>{{ form.singleLayerArea || "/" }}</div></template
        >
        <template v-if="form.type === 2" slot="netLayerHeight"
          ><div>{{ form.netLayerHeight || "/" }}</div></template
        >
        <template v-if="form.type === 2" slot="parkSpace"
          ><div>{{ form.parkSpace || "/" }}</div></template
        >
        <template v-if="form.type === 2" slot="airConditionType"
          ><div>{{ form.airConditionType || "/" }}</div></template
        >
        <template v-if="form.type === 2" slot="propertyFees"
          ><div>{{ form.propertyFees || "/" }}</div></template
        >
        <template v-if="form.type === 2" slot="propertyType"
          ><div>{{ form.propertyType || "/" }}</div></template
        >
        <template v-if="form.type === 2" slot="developer"
          ><div>{{ form.developer || "/" }}</div></template
        >
        <template v-if="form.type === 2" slot="propertyManagement"
          ><div>{{ form.propertyManagement || "/" }}</div></template
        >

      <template slot="titleTwoSonThree">
        <h4>房源资料</h4>
      </template>
      <template slot="titleTwoSonFour"><div></div></template>
      <template slot="houseOwner"
        ><div>{{ form.houseOwner }}</div></template
      >
      <template slot="housePhone"
        ><div>{{ form.housePhone }}</div></template
      >
      <template slot="idCard"
        ><div>{{ form.idCard }}</div></template
      >
      <template slot="signType"
        ><div>
          {{
            form.signType === 1
              ? "线上签订"
              : form.signType === 2
              ? "手动上传授权委托书"
              : "/"
          }}
        </div></template
      >
      <template slot="signTypeOne">
        <el-button
          style="background: #c280ff; color: #ffffff; border-color: #c280ff"
          @click="viewAgreement"
          >查看协议</el-button
        >
      </template>
      <template slot="menuForm">
        <el-button style="padding: 9px 40px" @click="handleBack"
          >返 回</el-button
        >
      </template>
    </avue-form>
  </div>
</template>

<script>
import { view } from "@/api/house/view";
import { viewContract } from "@/api/house/second";
import { orientation, floor, facilities } from "../tenancyExamine/data";
export default {
  name: "detail",
  data() {
    return {
      labelList: "",
      orientation,
      orientationText: "",
      floor,
      floorText: "",
      facilities,
      facilitiesText: "",
      loading: false,
      form: {},
      formOption: {
        submitBtn: false,
        emptyBtn: false,
        labelWidth: 200,
        disabled: true,
        column: [
          {
            label: "",
            prop: "titleOne",
            labelWidth: 100,
            span: 24,
          },
          {
            label: "客户信息",
            prop: "username",
            span: 24,
          },
          {
            label: "客户手机号",
            prop: "phone",
            span: 24,
          },
          {
            label: "预约时间",
            prop: "subscribeTime",
            span: 24,
          },
          {
            label: "需求描述",
            prop: "requireDesc",
            span: 24,
          },
          {
            label: "带看状态",
            prop: "state",
            span: 24,
          },
          {
            label: "",
            prop: "titleTwo",
            labelWidth: 100,
            span: 24,
          },
          {
            label: "",
            prop: "titleTwoSonOne",
            labelWidth: 120,
            span: 24,
          },
          {
            label: "房源标题",
            prop: "title",
            span: 24,
            rules: [{ required: true }],
          },
          {
            label: "城市",
            prop: "city",
            span: 24,
            rules: [{ required: true }],
          },
          {
            label: "商圈",
            prop: "business",
            span: 24,
            rules: [{ required: true }],
          },
          {
            label: "楼盘名称",
            prop: "name",
            span: 24,
            rules: [{ required: true }],
          },
          {
            label: "房屋地址",
            prop: "buildNum",
            span: 24,
            rules: [{ required: true }],
          },
          {
            label: "出租方式",
            prop: "rentalMode",
            span: 24,
            rules: [{ required: true }],
            display: false,
          },
          {
            label: "户型",
            prop: "bedroom",
            span: 24,
            rules: [{ required: true }],
          },
          {
            label: "建筑面积",
            prop: "floorage",
            span: 24,
            rules: [{ required: true }],
            display: false,
          },
          {
            label: "出租面积",
            prop: "rentalArea",
            span: 24,
            rules: [{ required: true }],
            display: false,
          },
          {
            label: "期望售价",
            prop: "sellPrice",
            span: 24,
            rules: [{ required: true }],
            display: false,
          },
          {
            label: "期望租金",
            prop: "expectPrice",
            span: 24,
            rules: [{ required: true }],
            display: false,
          },
          {
            label: "建筑年代",
            prop: "buildYear",
            span: 24,
            rules: [{ required: true }],
          },
          {
            label: "标签",
            prop: "labelList",
            span: 24,
            rules: [{ required: true }],
          },
          {
            label: "付款方式",
            prop: "pledge",
            span: 24,
            rules: [{ required: true }],
            display: false,
          },
          {
            label: "",
            prop: "titleTwoSonTwo",
            labelWidth: 120,
            span: 24,
          },
          {
            label: "朝向",
            prop: "orientation",
            span: 24,
            rules: [{ required: true }],
          },
          {
            label: "楼层",
            prop: "floor",
            span: 24,
            rules: [{ required: true }],
            display: false,
          },
          {
            label: "楼层",
            prop: "floorCurrent",
            span: 24,
            rules: [{ required: true }],
            display: false,
          },
          {
            label: "装修",
            prop: "decorate",
            span: 24,
            rules: [{ required: true }],
            display: false,
          },
          {
            label: "电梯",
            prop: "elevator",
            span: 24,
            rules: [{ required: true }],
          },
          {
            label: "楼型",
            prop: "buildType",
            span: 24,
            rules: [{ required: true }],
            display: false,
          },
          {
            label: "用途",
            prop: "purpose",
            span: 24,
            rules: [{ required: true }],
            display: false,
          },
          {
            label: "权属",
            prop: "ownership",
            span: 24,
            rules: [{ required: true }],
            display: false,
          },
          {
            label: "抵押情况",
            prop: "mortgage",
            span: 24,
            rules: [{ required: true }],
            display: false,
          },

          {
            label: "入住",
            prop: "checkType",
            span: 24,
            rules: [{ required: true }],
            display: false,
          },
          {
            label: "设施",
            prop: "facilities",
            span: 24,
            rules: [{ required: true }],
            display: false,
          },
          {
            label: "供暖方式",
            prop: "heatMode",
            span: 24,
            rules: [{ required: true }],
          },
          {
            label: "房本年限",
            prop: "ageLimit",
            span: 24,
            rules: [{ required: true }],
            display: false,
          },
          {
            label: "上次交易",
            prop: "lastTime",
            span: 24,
            rules: [{ required: true }],
            display: false,
          },
          {
            label: "是否精装",
            prop: "isHardcover",
            span: 24,
            rules: [{ required: true }],
            display: false,
          },
          {
            label: "水电",
            prop: "hydropower",
            span: 24,
            rules: [{ required: true }],
            display: false,
          },
          {
            label: "业态类型",
            prop: "businessType",
            span: 24,
            rules: [{ required: true }],
            display: false,
          },
          {
            label: "总楼层",
            prop: "floorTotal",
            span: 24,
            display: false,
          },
          {
            label: "层高",
            prop: "floorHeight",
            span: 24,
            display: false,
          },
          {
            label: "单层面积",
            prop: "singleLayerArea",
            span: 24,
            display: false,
          },
          {
            label: "净层高",
            prop: "netLayerHeight",
            span: 24,
            display: false,
          },
          {
            label: "停车位",
            prop: "parkSpace",
            span: 24,
            display: false,
          },
          {
            label: "空调类型",
            prop: "airConditionType",
            span: 24,
            display: false,
          },
          {
            label: "物业费",
            prop: "propertyFees",
            span: 24,
            display: false,
          },
          {
            label: "楼盘类型",
            prop: "propertyType",
            span: 24,
            display: false,
          },
          {
            label: "开发商",
            prop: "developer",
            span: 24,
            display: false,
          },
          {
            label: "物业公司",
            prop: "propertyManagement",
            span: 24,
            display: false,
          },
          {
            label: "",
            prop: "titleTwoSonThree",
            labelWidth: 120,
            span: 24,
          },
          {
            label: "房源图片上传",
            prop: "images",
            type: "upload",
            span: 24,
            dataType: "string",
            listType: "picture-card",
            rules: [{ required: true }],
          },
          {
            label: "房主身份证信息",
            prop: "titleTwoSonFour",
            span: 24,
            rules: [{ required: true }],
          },
          {
            label: "房主姓名",
            prop: "houseOwner",
            span: 24,
            rules: [{ required: true }],
          },
          {
            label: "房主手机号",
            prop: "housePhone",
            span: 24,
            rules: [{ required: true }],
          },
          {
            label: "身份证号",
            prop: "idCard",
            span: 24,
            rules: [{ required: true }],
          },
          {
            label: "房主身份证正反面",
            prop: "idCardFront",
            type: "upload",
            span: 24,
            dataType: "string",
            listType: "picture-card",
            rules: [{ required: true }],
          },
          {
            label: "",
            prop: "idCardBack",
            type: "upload",
            span: 24,
            dataType: "string",
            listType: "picture-card",
            rules: [{ required: true }],
          },
          {
            label: "房本上传",
            prop: "premisesPermit",
            type: "upload",
            span: 24,
            dataType: "string",
            listType: "picture-card",
            rules: [{ required: true }],
          },
          {
            label: "签订挂牌委托协议",
            prop: "signType",
            span: 24,
            rules: [{ required: true }],
          },
          {
            label: "",
            prop: "signTypeOne",
            span: 24,
            display: false,
          },
          {
            label: "",
            prop: "signTypeImage",
            type: "upload",
            dataType: "string",
            listType: "picture-card",
            span: 24,
            display: false,
          },
        ],
      },
    };
  },
  async mounted() {
    // this.loading = true
    await this.getDetail();
  },
  methods: {
    async getDetail() {
      const { data } = await view({ id: this.$route.query.id });
      console.log(data);
      const { labelList, ...rest } = data;
      this.labelList = labelList.toString();
      let orientationArr = data.orientation.split(",");
      this.orientationText = this.orientation
        .map((item) => {
          if (orientationArr.includes(item.value)) {
            return item.label;
          }
        })
        .filter((item) => item)
        .join(";");

      this.form = {
        ...rest,
      };
      const rentalMode = this.findObject(this.formOption.column, "rentalMode");
      const floorage = this.findObject(this.formOption.column, "floorage");
      const rentalArea = this.findObject(this.formOption.column, "rentalArea");
      const sellPrice = this.findObject(this.formOption.column, "sellPrice");
      const expectPrice = this.findObject(
        this.formOption.column,
        "expectPrice"
      );
      const pledge = this.findObject(this.formOption.column, "pledge");
      const floor = this.findObject(this.formOption.column, "floor");
      const floorCurrent = this.findObject(
        this.formOption.column,
        "floorCurrent"
      );
      const decorate = this.findObject(this.formOption.column, "decorate");

      const buildType = this.findObject(this.formOption.column, "buildType");
      const purpose = this.findObject(this.formOption.column, "purpose");
      const ownership = this.findObject(this.formOption.column, "ownership");
      const mortgage = this.findObject(this.formOption.column, "mortgage");
      const ageLimit = this.findObject(this.formOption.column, "ageLimit");
      const lastTime = this.findObject(this.formOption.column, "lastTime");

      const checkType = this.findObject(this.formOption.column, "checkType");
      const facilities = this.findObject(this.formOption.column, "facilities");
      const isHardcover = this.findObject(
        this.formOption.column,
        "isHardcover"
      );
      const hydropower = this.findObject(this.formOption.column, "hydropower");

      const businessType = this.findObject(
        this.formOption.column,
        "businessType"
      );
      const floorTotal = this.findObject(this.formOption.column, "floorTotal");
      const floorHeight = this.findObject(
        this.formOption.column,
        "floorHeight"
      );
      const singleLayerArea = this.findObject(
        this.formOption.column,
        "singleLayerArea"
      );
      const netLayerHeight = this.findObject(
        this.formOption.column,
        "netLayerHeight"
      );
      const parkSpace = this.findObject(this.formOption.column, "parkSpace");
      const airConditionType = this.findObject(
        this.formOption.column,
        "airConditionType"
      );
      const propertyFees = this.findObject(
        this.formOption.column,
        "propertyFees"
      );
      const propertyType = this.findObject(
        this.formOption.column,
        "propertyType"
      );
      const developer = this.findObject(this.formOption.column, "developer");
      const propertyManagement = this.findObject(
        this.formOption.column,
        "propertyManagement"
      );

      if (rest.type === 1) {
        rentalMode.display = false;
        floorage.display = true;
        rentalArea.display = false;
        sellPrice.display = true;
        expectPrice.display = false;
        pledge.display = false;
        floor.display = true;
        floorCurrent.display = false;
        decorate.display = true;

        buildType.display = true;
        purpose.display = true;
        ownership.display = true;
        mortgage.display = true;
        ageLimit.display = true;
        lastTime.display = true;

        this.floor.map((item) => {
          if (item.value === data.floor) {
            this.floorText = item.label;
          }
        });
      } else {
        rentalMode.display = true;
        floorage.display = false;
        rentalArea.display = true;
        sellPrice.display = false;
        expectPrice.display = true;
        pledge.display = true;
        floor.display = false;
        floorCurrent.display = true;
        decorate.display = false;

        checkType.display = true;
        facilities.display = true;
        isHardcover.display = true;
        hydropower.display = true;

        businessType.display = true;
        floorTotal.display = true;
        floorHeight.display = true;
        singleLayerArea.display = true;
        netLayerHeight.display = true;
        parkSpace.display = true;
        airConditionType.display = true;
        propertyFees.display = true;
        propertyType.display = true;
        developer.display = true;
        propertyManagement.display = true;

        let facilitiesArr = data.facilities.split(",");
        this.facilitiesText = this.facilities
          .map((item) => {
            if (orientationArr.includes(item.value)) {
              return item.label;
            }
          })
          .filter((item) => item)
          .join(";");
        console.log(facilitiesArr);
      }

      const signTypeOne = this.findObject(
        this.formOption.column,
        "signTypeOne"
      );
      const signTypeImage = this.findObject(
        this.formOption.column,
        "signTypeImage"
      );
      if (data.signType === 1) {
        signTypeOne.display = true;
        signTypeImage.display = false;
      } else if (data.signType === 2) {
        signTypeOne.display = false;
        signTypeImage.display = true;
      } else {
        signTypeOne.display = false;
        signTypeImage.display = false;
      }

      this.loading = false;
    },
    // 查看协议
    async viewAgreement() {
      const { data } = await viewContract({
        id: this.form.contractId,
      });
      // console.log(data)
      window.open(data.pageUrl, "_blank");
    },
    // 返回
    handleBack() {
      this.$router.go(-1);
    },
  },
};
</script>

<style scoped>
</style>